import React, { useEffect, useState } from 'react'
import PilotCta from '../PilotCta'

export default ({ showCookieCta }) => {
  const [isBottom, setIsBottom] = useState(false)
  const [footerWrapperHeight, setFooterWrapperHeight] = useState(0)

  useEffect(() => {
    setIsBottom(detectScrollAtBottom())
    window.addEventListener('scroll', handleScroll)
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [])

  const getElementHeight = (id) => {
    return document.getElementById(id)?.clientHeight
  }

  const detectScrollAtBottom = () => {
    const windowHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.offsetHeight
    const { body } = document
    const html = document.documentElement
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const windowBottom = Math.round(windowHeight + window.pageYOffset)
    const difference = docHeight - windowBottom
    const additional = difference >= 1 && difference <= 2 ? difference : 0
    setFooterWrapperHeight(getElementHeight('site-footer') + getElementHeight('pilot-cta') - 1 + windowBottom - docHeight)
    return windowBottom + additional + getElementHeight('site-footer') >= docHeight
  }

  const handleScroll = () => {
    setIsBottom(detectScrollAtBottom())
  }
  
  return (
    <div className='bottom-wrapper'>
      {
        showCookieCta &&
        <div className='cookie-notice'>
          <div className='cookie-notice-content'>
            <div className='cookie-notice-content-left'>
              <p>
                ChartMedic.com uses cookies to provide necessary website functionality,
                improve your experience and analyze our traffic. By using our website, you agree to our
                <a className='cookie-link' href='/privacy-terms'> Privacy Policy</a> and our cookies usage.
              </p>
            </div>
            <div className='cookie-notice-content-right'>
              <input
                className='cookie-button'
                type='submit'
                id='btn-submit'
                onFocus={() => {
                  document.getElementById('btn-submit').style.border =
                    '3px solid grey'
                }}
                onBlur={() => {
                  document.getElementById('btn-submit').style.border = 'none'
                }}
                onClick={() => {
                  document.getElementsByClassName(
                    'cookie-notice'
                  )[0].style.display = 'none'
                }}
                value='X'
              />
            </div>
          </div>
        </div>
      }
      <div style={{ height: isBottom ? footerWrapperHeight : 'unset' }}>
        <PilotCta />
      </div>
    </div>
  )
}
