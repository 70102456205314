import React from 'react'
import {
  graphql,
} from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageHero from '../../components/Blocks/PageHero'
import {
  Page, Main, SectionHeader,
} from '../../components/StyledComponents'
import Title from '../../components/Base/Title'
import Paragraph from '../../components/Base/Paragraph'
import Section from '../../components/Base/Section'
import SectionWrap from '../../components/Base/Section/Wrap'
import BottomOverlay from '../../components/Blocks/BottomOverlay'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/hero-how-it-works-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "page-hero/hero-how-it-works.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({
  data,
}) => {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 800px)',
    },
  ]
  return (
    <Layout>
      <SEO
        title='Privacy & Terms'
        description='ChartMedic privacy and terms policies'
      />
      <Page>
        <Header />
        <Main>
          <PageHero
            background={sources}
            title='Privacy and Terms'
          />
          <SectionWrap theme='grey'>
            <Section center>
              <Section theme='text-box'>
                <Title level={2}>ChartMedic Privacy Policy</Title>
                <Paragraph>
                  <SectionHeader>Effective date: November 16, 2021</SectionHeader>
                </Paragraph>
                <Paragraph>
                  This policy explains what information we collect when you use the ChartMedic
                  site and when you or your patients use the accompanying mobile applications.
                  It also describes how we store, use, transfer, and delete that information.
                </Paragraph>
                <Paragraph>
                  Our aim is not only to comply with privacy law, but to earn your trust.
                </Paragraph>

                <Title level={3}>Terms Used in this Policy</Title>
                <Paragraph>
                  ChartMedic refers to our business organization and ChartMedic refers specifically to the corresponding website, which is
                  wholly owned by the business organization.
                </Paragraph>

                <Title level={3}>Information We Collect & How We Use It</Title>

                <SectionHeader>Information Collected From ChartMedic Users</SectionHeader>
                <Paragraph>
                  ChartMedic doesn’t make money from ads. So we do not collect information
                  to facilitate advertising to you. The tracking we do is there to make our
                  service work as well as possible.
                </Paragraph>

                <Paragraph>
                  In order to provide the best possible experience using ChartMedic, we
                  collect information from your interactions with our network. Some of this information, you provide (such as your email address). Other information, we
                  collect based on your actions while using ChartMedic, such as which pages
                  you visit and your interactions with features of those pages. This information
                  includes records of your interactions, your Internet Protocol address, information about your device (such as browser type), and referral information.
                </Paragraph>

                <Paragraph>
                  We use this information to:
                  <ul>
                    <li>Provide, test, improve, promote, and personalize ChartMedic.</li>
                    <li>Generate aggregate, non-identifying information about how people use ChartMedic services.</li>
                    <li>Prevent theft of ChartMedic content.</li>
                  </ul>
                </Paragraph>
                <Paragraph>
                  We do not ask you to authenticate with a third party service (like Facebook, Google, or Twitter) and we do not collect information about you from
                  third parties.
                </Paragraph>

                <SectionHeader>Information Collected from Patients</SectionHeader>
                <Paragraph>
                  We collect the following information anonymously from the interactions of patients with the ChartMedic mobile app (herein referred to as an interview):
                  <ol>
                    <li>Clinic the patient is visiting.</li>
                    <li>Provider with whom the patient’s appointment has been scheduled (for scheduled appointments).</li>
                    <li>Time and date of the appointment (for scheduled appointments).</li>
                    <li>Time and date the interview was completed.</li>
                    <li>Elapsed time for completion of each question answered by the patient, and for completion of the entire interview.</li>
                    <li>The patient-reported year of birth if it is less than 88 years prior to the date of interview completion.</li>
                    <li>Answers provided by the patient.</li>
                  </ol>
                </Paragraph>
                <Paragraph>
                  We do not collect any of the eighteen patient identifiers enumerated in the Health Insurance Portability and Accountability Act (HIPAA).
                </Paragraph>
                <Paragraph>
                  Each instance of our installed software on a mobile device has a unique identifier which is generated upon app installation. This identifier is used
                  to associate software installations with clinics and is in no way generated from or related to any specific identifier of the device upon which the software
                  is installed, such as device unique telephony numbers (IMEI, MEID, ESN, and IMSI), MAC addresses, Android build Serial Numbers, Android ANDROID IDs, and iOS UDIDs.
                  This identifier is never directly associated with a patient’s interview responses.
                </Paragraph>
                <Title level={3}>Information Disclosure</Title>

                <SectionHeader>Disclosure of Information Collected From ChartMedic Users</SectionHeader>
                <Paragraph>
                  ChartMedic will not transfer information about you to third parties for the purpose of providing or facilitating third-party advertising to you. We will
                  not sell information about you.
                </Paragraph>
                <Paragraph>
                  We may share your account information with third parties in the following circumstances:
                  <ol>
                    <li>When we have asked you to do so, and you have consented.</li>
                    <li>To a partner or service provider who meets our data protection standards for purposes of providing ChartMedic services.</li>
                    <li>When we have a good faith belief it is required by law, such as pursuant to a subpoena or other legal process.</li>
                    <li>When we have a good faith belief that doing so will help prevent imminent harm to someone.</li>
                  </ol>
                </Paragraph>
                <Paragraph>
                  If we are going to share your information in response to legal process, you will be notified so you can challenge it (for example by seeking court
                  intervention), unless we’re prohibited by law or believe doing so may endanger others. We will object to requests for information about users of our services
                  that we believe are improper.
                </Paragraph>
                <SectionHeader>Disclosure of Information Collected from Patients</SectionHeader>
                <Paragraph>
                  ChartMedic will not transfer any information regarding a patient’s answers to questions answered within an interview to any third party except for the
                  purpose of data storage (see next section).
                </Paragraph>
                <Title level={3}>Data Storage</Title>
                <Paragraph>
                  ChartMedic uses third party vendors and hosting partners, such as Amazon, for hardware, software, networking, storage, and related technology we need
                  to provide our service. We maintain logs of interactions with servers and with mobile applications. By using ChartMedic, you authorize ChartMedic
                  to transfer, store, and use your information in the United States and any other country where we operate.
                </Paragraph>
                <Title level={3}>Cookies and Tracking</Title>
                <Paragraph>
                  ChartMedic tracks you only when you are interacting with the ChartMedic network and sub-networks. ChartMedic does not track you across the Internet.
                  Some third-party services that we use to provide the ChartMedic service, such as Google Analytics (<a href='http://www.google.com/policies/privacy/partners/'>http://www.google.com/policies/privacy/partners/</a>),
                  may place their own cookies in your browser. This Privacy Policy covers use of cookies by ChartMedic only and not the use of cookies by third parties.
                </Paragraph>

                <Paragraph>
                  We use Google Analytics for aggregated, anonymized website traffic analysis. In order to track your session usage, Google drops a cookie (_ga) with a
                  randomly-generated ClientID in your browser. This ID is anonymized and contains no identifiable information like email, phone number, name, etc.
                  We also send Google your IP Address.  We do not send Google any information that uniquely identifies you to Google or to us.
                </Paragraph>

                <Paragraph>
                  We use GA to track aggregated website behavior, such as what pages you looked at, for how long, and so on. This information is important to us for improving
                  the user experience and determining site effectiveness.
                </Paragraph>

                <Paragraph>
                  If you would like to access what browsing information we have - or ask us to delete any GA data - please delete your _ga cookies, reach out to us via <a href='/about/#contact'>this form</a>,
                  and/or install the Google Analytics Opt-Out Browser Add-On.
                </Paragraph>


                <Title level={3}>Modifying or Deleting Your Personal Information</Title>
                <Paragraph>
                  If you have a ChartMedic account, you can access or modify your personal information, or delete your account <a href='https://clinicportal.chartmedic.com/settings'>here</a>.
                </Paragraph>
                <Paragraph>
                  To protect information from accidental or malicious destruction, we may maintain residual copies for a brief time period. But, if you delete your
                  account, your information and content will be unrecoverable after that time. ChartMedic may preserve and maintain copies of your information when
                  required to do so by law.
                </Paragraph>

                <Title level={3}>Data Security</Title>
                <Paragraph>
                  We use encryption (HTTPS/TLS) to protect data transmitted to and from our site. However, no data transmission over the Internet is 100% secure,
                  so we can’t guarantee security. You use the Service at your own risk, and you’re responsible for taking reasonable measures to secure your account.
                </Paragraph>

                <Title level={3}>Email from ChartMedic</Title>
                <Paragraph>
                  Sometimes we’ll send you emails about your account, service changes, or new policies. You cannot opt put of this “transactional” email (unless you
                  delete your account). But, you can opt out of non-administrative emails such as digests, newsletters, and activity notifications through your account’s
                  &quot;Settings&quot; <a href='https://clinicportal.chartmedic.com/settings'>page</a>.
                </Paragraph>
                <Paragraph>
                  When you interact with an email sent from ChartMedic (such as opening an email or clicking on a particular link in an email), we may receive information
                  about that interaction. We won’t email you to ask for your password or other account information. If you receive such an email, please send it to
                  us so we can investigate.
                </Paragraph>

                <Title level={3}>Changes to this Policy</Title>
                <Paragraph>
                  ChartMedic may periodically update this policy. We’ll notify you about significant changes to it. The most current version of the policy will always
                  be <a href='/privacy-terms'>here</a>, and we will archive former versions of this policy <a href='/privacy-terms'>here</a>.
                </Paragraph>

                <Title level={3}>Questions</Title>
                <Paragraph>
                  We welcome feedback about this policy at <a href='mailto:legal@chartmedic.com'>legal@chartmedic.com</a>.
                </Paragraph>
              </Section>
            </Section>
          </SectionWrap>
        </Main>
      </Page>
      <Footer />
      <BottomOverlay />
    </Layout>
  )
}
